/* eslint-disable max-lines */

import React, { useEffect, useState, useMemo } from 'react';
import { compose } from 'redux';
import { find } from 'lodash/fp';
import Box from '@rexlabs/box';
import { Heading, Body } from '@rexlabs/text';
import { useEntityQuery, withModel, withQuery } from '@rexlabs/model-generator';
import { ReactForms, Form, Field } from '@rexlabs/form';
import {
  PrimaryButton,
  GhostButton,
  ButtonGroup,
  DestructiveButton
} from '@rexlabs/button';
import { Checkbox } from '@rexlabs/checkbox';
import { RenderLoading } from 'view/components/render-loading';
import themeSettingsModel from 'data/models/entities/themesettings';
import blockEntity, { Block, BlockModel } from 'data/models/entities/blocks';
import _, { map } from 'lodash';
import ThemeSettingInput from './theme-setting-input';
import { useToast } from 'view/components/toast';
import { DeleteBlockDialog } from 'view/components/theme-settings/DeleteBlockDialog';
import { websiteQuery } from 'data/queries/websites';
import { getThemeQuery } from 'data/queries/themes';
import { TESTIMONIAL_OPTIONS } from 'view/components/theme-settings/dynamic-inputs/testimonialsInput';
import { COLOR_VARIANT_OPTIONS } from 'view/components/theme-settings/dynamic-inputs/colorVariantInput';
import { BRAND_COLOR_VARIANT_OPTIONS } from 'view/components/theme-settings/dynamic-inputs/brandColorVariantInput';
import { BLOCK_VARIANT_OPTIONS } from 'view/components/theme-settings/dynamic-inputs/blockVariantInput';
import { LAUNCHPAD_VARIANT_OPTIONS } from 'view/components/theme-settings/dynamic-inputs/launchpadVariantInput';
import { CONTENT_CONTAINER_VARIANTS } from 'view/components/theme-settings/dynamic-inputs/contentContainerVariant';
import { CONTENT_BLOCK_ALIGNMENT } from 'view/components/theme-settings/dynamic-inputs/contentBlockAlignment';

interface BlockItemProps {
  settingId: string | null;
  back: () => void;
  updatePreviewProps: () => void;
  refreshBlockList: () => void;
  websites: {
    item: {
      data: {
        theme_id: string;
        id: string;
      };
    };
  };
}

// TODO: Fill in types
interface Props {
  settings?: any;
  blocks?: BlockModel;
  themeSettings?: any;
  match: any;
  blockSettingsForm: any;
}

function getWebsiteId({ match }) {
  return match.params?.websiteId;
}

const settingsToValues = (settingsData) => {
  const agencyData = [];
  const data = Object.assign(
    {},
    ...settingsData?.map((object) => {
      let value = object?.value ? object?.value[0] : null;

      // type conversion
      if (object.type === 'checkbox') {
        value = Boolean(value);
      } else if (object.type === 'number' || object.type === 'slider') {
        value = String(value);
      } else if (object.type === 'blog') {
        switch (value) {
          case 'featured_posts':
            value = { value: 'featured_posts', label: 'Featured Blog Posts' };
            break;
          case 'latest_posts':
            value = {
              value: 'latest_posts',
              label: 'Last Updated Blog Posts'
            };
            break;
          default:
            value = null;
        }
      } else if (object.type === 'listings') {
        const options = {
          featured_available: {
            value: 'featured_available',
            label: 'Featured (Available)'
          },
          featured_all: {
            value: 'featured_all',
            label: 'Featured (All Listings)'
          },
          recent_all: {
            value: 'recent_all',
            label: 'Recent Listings (Sales & Rentals - including sold & leased)'
          },
          recent_avail_all: {
            value: 'recent_avail_all',
            label: 'Recent Available (Sales & Rentals)'
          },
          recent_avail_sales: {
            value: 'recent_avail_sales',
            label: 'Recent Available (Sales Only)'
          },
          recent_avail_rental: {
            value: 'recent_avail_rental',
            label: 'Recent Available (Rentals Only)'
          },
          recent_avail_commercial: {
            value: 'recent_avail_commercial',
            label: 'Available – All (Commercial only)'
          },
          recent_avail_auction: {
            value: 'recent_avail_auction',
            label: 'Available – All (Auction only)'
          },
          recent_sold_leased_all: {
            value: 'recent_sold_leased_all',
            label: 'Recent Sold & Leased Listings'
          },
          recent_sold_sales: {
            value: 'recent_sold_sales',
            label: 'Recent Sold Listings'
          },
          recent_leased_rental: {
            value: 'recent_leased_rental',
            label: 'Recent Leased Listings'
          }
        };
        value = options?.[value] ?? null;
      } else if (object.type === 'banner-height') {
        const options = {
          50: {
            value: '50',
            label: '50% screen height'
          },
          75: {
            value: '75',
            label: '75% screen height'
          },
          100: {
            value: '100',
            label: '100% screen height'
          }
        };
        value = options?.[value] ?? null;
      } else if (object.type === 'testimonials') {
        value = TESTIMONIAL_OPTIONS?.[value] ?? null;
      } else if (object.type === 'staff') {
        const options = {
          featured: { value: 'featured', label: 'Featured Staff' },
          all: { value: 'all', label: 'All Staff' }
        };
        value = options?.[value] ?? null;
      } else if (object.type === 'content-container-variant') {
        value = CONTENT_CONTAINER_VARIANTS?.[value] ?? null;
      } else if (object.type === 'post-type:agency') {
        const agency: any = _.find(agencyData, ['id', value]);
        value = {
          value: agency?.id,
          label: agency?.title
        };
      } else if (object.type === 'image' || object.type === 'multi-image') {
        // const fileList = props && props?.files?.list?.items;
        const fileList = [];
        const id = object.value?.[0] ?? object.value;
        value = find((file: any) => file?.id === id)(fileList);
      } else if (object.type === 'color-variant') {
        value = COLOR_VARIANT_OPTIONS?.[value] ?? null;
      } else if (object.type === 'brand-color-variant') {
        value = BRAND_COLOR_VARIANT_OPTIONS?.[value] ?? null;
      } else if (object.type === 'block-variant') {
        value = BLOCK_VARIANT_OPTIONS?.[value] ?? null;
      } else if (object.type === 'launchpad-variant') {
        value = LAUNCHPAD_VARIANT_OPTIONS?.[value] ?? null;
      } else if (object.type === 'content-block-alignment') {
        value = CONTENT_BLOCK_ALIGNMENT?.[value] ?? null;
      }
      return { [object.key]: value };
    })
  );

  return data;
};

function BlockItem({
  back,
  blocks,
  themeSettings,
  settingId,
  updatePreviewProps,
  refreshBlockList,
  websites,
  ...rest
}: BlockItemProps & Props) {
  const [hasSettings, setHasSettings] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [block, setBlock] = useState<Block | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { addToast } = useToast();

  const { theme_id: themeId } = websites.item.data;

  const themeQuery = useMemo(() => {
    if (themeId) return getThemeQuery(themeId);
  }, [themeId]);

  const { status: themeStatus, data: themeData } = useEntityQuery(themeQuery);

  useEffect(() => {
    if (settingId && !hasSettings) {
      setHasSettings(true);
      setFetching(true);
      blocks
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        ?.fetchItem?.({
          id: settingId,
          args: {
            websiteId: getWebsiteId(rest),
            id: settingId,
            include: 'settings'
          }
        })
        .then((res) => {
          setFetching(false);
          setBlock(res.data);
        });
    }
  }, [themeSettings, settingId, hasSettings, rest, setBlock, blocks]);

  const settingItems = useMemo(
    () => _.orderBy(block?.settings?.data, 'priority', 'desc'),
    [block]
  );

  const initialValues = useMemo(() => {
    return {
      ...settingsToValues(settingItems),
      enabled: !!block?.enabled
    };
  }, [settingItems, block]);

  const handleSubmit = (values) => {
    const data = map(values, (value, key) => {
      let newValue = [value?.value ?? value];
      const fieldType = settingItems.find((s) => s.key === key)?.type;

      // image uploads
      if (fieldType === 'multi-image' || fieldType === 'image') {
        newValue = Array.isArray(value)
          ? [value.map((v) => v?.id || v)]
          : [value?.id || value];
      }

      return {
        key,
        value: newValue
      };
    });

    return Promise.all(
      [
        // Update theme settings
        themeSettings.updateSettings({
          websiteId: getWebsiteId(rest),
          blockId: settingId,
          data: {
            settings: data
          }
        }),
        // Update block if not static
        block?.static
          ? null
          : blocks?.updateItem?.({
              id: settingId,
              args: {
                websiteId: getWebsiteId(rest),
                enabled: values?.enabled
              }
            })
      ].filter((a) => a !== null)
    )
      .then(() => {
        updatePreviewProps();
      })
      .catch((e) =>
        addToast({
          title: 'Error',
          type: 'error',
          content: e?.message ?? 'An unknown error occurred'
        })
      );
  };

  const handleBlockDeleted = () => {
    addToast({
      type: 'success',
      title: 'Block Successfully Deleted',
      content: 'The website preview will update shortly'
    });
    refreshBlockList();
    updatePreviewProps();
    setHasSettings(false);
    setBlock(null);
    back();
  };

  return (
    <RenderLoading isLoading={fetching || themeStatus === 'loading'}>
      <Box p={24}>
        {!settingItems.length ? (
          <Body>No settings found...</Body>
        ) : (
          <ReactForms initialValues={initialValues} handleSubmit={handleSubmit}>
            {({ isSubmitting, submitForm, setFieldValue }) => (
              <Form>
                <Box
                  id={'block-header'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  marginBottom={16}
                >
                  <Heading>{block?.label}</Heading>
                  <ButtonGroup>
                    <GhostButton
                      IconLeft={() => <span>&lt;</span>}
                      onClick={() => {
                        setHasSettings(false);
                        setBlock(null);
                        back();
                      }}
                    >
                      Back
                    </GhostButton>
                    <PrimaryButton
                      onClick={() => {
                        // trackSave();
                        submitForm();
                      }}
                      isLoading={isSubmitting}
                    >
                      Save
                    </PrimaryButton>
                  </ButtonGroup>
                </Box>
                <hr />

                <Box flexDirection={'column'} style={{ height: '100%' }}>
                  {block && !(block?.static && block?.enabled === null) && (
                    <Box p={10}>
                      <Field
                        label={'Enabled'}
                        name={'enabled'}
                        optional={false}
                        Input={Checkbox}
                      />
                    </Box>
                  )}

                  {settingItems?.map((setting) => {
                    if (setting.type === 'heading') {
                      return (
                        <Box p={'1rem'}>
                          <Heading level={2} css={{ fontSize: 18 }}>
                            {setting.label}
                          </Heading>
                        </Box>
                      );
                    }

                    return (
                      <ThemeSettingInput
                        key={setting.key}
                        setting={setting}
                        setFieldValue={setFieldValue}
                        persona={themeData.key}
                        {...rest}
                      />
                    );
                  })}
                </Box>
                {themeData.key === 'minimal' ? null : (
                  <Box padding='10px'>
                    {block?.static ? null : (
                      <DestructiveButton
                        style={{ width: '100%' }}
                        onClick={() => setOpenDeleteDialog(true)}
                      >
                        Delete Block
                      </DestructiveButton>
                    )}
                  </Box>
                )}
              </Form>
            )}
          </ReactForms>
        )}
      </Box>
      {openDeleteDialog && (
        <DeleteBlockDialog
          close={() => setOpenDeleteDialog(false)}
          blockId={block?.id ?? ''}
          websiteId={getWebsiteId(rest)}
          onSuccess={handleBlockDeleted}
        />
      )}
    </RenderLoading>
  );
}

// TODO Remove <any, any...> hint to compose once the passed functions have better typing.
export default compose<any>(
  withModel(themeSettingsModel),
  withModel(blockEntity),
  withQuery(websiteQuery)
)(BlockItem);
